import React from 'react';
import { render, screen, act, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';
import { CreateCoverageRulesFormNew } from '../Integrations/CoverageRulesFormNew';
import { within } from '@testing-library/dom';

let server;

describe('<CreateCoverageRulesFormNew />', () => {
  beforeEach(() => {
    const mockIntersectionObserver = jest.fn();
    mockIntersectionObserver.mockReturnValue({
      observe: () => null,
      unobserve: () => null
    });
    window.IntersectionObserver = mockIntersectionObserver;
    server = makeServer();

    render(
      <Wrapper>
        <CreateCoverageRulesFormNew />
      </Wrapper>
    );
  });

  afterEach(() => {
    server.shutdown();
  });

  test('It renders the create PMS integration setup', async () => {
    const heading = await screen.findAllByText('PMS Integration Setup');

    expect(heading).toBeDefined();
  });

  test('It should show partner ruleset form', async () => {
    const showPartnerRuleset = await screen.getByRole('switch', { name: 'Toggle partner coverage rules' });
    expect(showPartnerRuleset).toBeDefined();

    await act(async () => {
      userEvent.click(showPartnerRuleset);
    });

    const InviteEligibility = await screen.findByText('Rhino Product Eligibility');
    expect(InviteEligibility).toBeDefined();

    const ScreeningResult = await screen.findByText('Screening Result');
    expect(ScreeningResult).toBeDefined();

    const CoverageAmounts = await screen.getByRole('button', { name: 'Add coverage amount' });
    expect(CoverageAmounts).toBeDefined();

    await act(async () => {
      userEvent.click(CoverageAmounts);
      const CoverageType = await waitFor(() => screen.getByRole('button', { name: 'Select coverage type' }));
      expect(CoverageType).toBeDefined();
      userEvent.click(CoverageType);
    });
      const unitMultiplier = await waitFor(() => screen.findByText('Unit/Property Multiplier'));
      expect(unitMultiplier).toBeDefined();
      await act(async () => {
        userEvent.click(unitMultiplier);
    });
    const MonthlyRentMultiplier = screen.findByText('Monthly rent multiplier');
    expect(MonthlyRentMultiplier).toBeDefined();
  });

  test('It should show custom ruleset form', async () => {
    const showCustomRuleset = await screen.getByRole('switch', { name: 'Toggle custom coverage rules' });
    expect(showCustomRuleset).toBeDefined();

    await act(async () => {
      userEvent.click(showCustomRuleset);
      const RulesetName = await screen.getByRole('button', { name: 'Add custom ruleset' });
      expect(RulesetName).toBeDefined();
      userEvent.click(RulesetName);
    });

    const RulesetName = screen.findByText('Ruleset name');
    expect(RulesetName).toBeDefined();

    const CoverageAmounts = await screen.getByRole('button', { name: 'Add coverage amount' });
    expect(CoverageAmounts).toBeDefined();

    await act(async () => {
      userEvent.click(CoverageAmounts);
      const CoverageType = await waitFor(() => screen.getByRole('button', { name: 'Select coverage type' }));
      expect(CoverageType).toBeDefined();
      userEvent.click(CoverageType);
    });
    const unitMultiplier = await waitFor(() => screen.findByText('Unit/Property Multiplier'));
    expect(unitMultiplier).toBeDefined();
    await act(async () => {
      userEvent.click(unitMultiplier);
    });
    const MonthlyRentMultiplier = screen.findByText('Monthly rent multiplier');
    expect(MonthlyRentMultiplier).toBeDefined();

    const ApplicableProperties = screen.findByText('Applicable properties');
    expect(ApplicableProperties).toBeDefined();
  });
});
