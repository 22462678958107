import React, { useState } from 'react';
import { CoverageValue, DisplayField, DisplayFieldControls, BoldText } from './Styled';
import { ActionButton, ButtonHabitat, TextInput } from '@sayrhino/rhino-shared-js';
import { Tooltip, TooltipText } from '../Tooltip';

interface IProps {
  chargeCodeValue: string;
  chargeCodeLabel: string;
  setChargeCode: (value: string) => void;
  updateChargeCode: () => void;
}

const ChargeCode = ({ chargeCodeValue, setChargeCode, updateChargeCode, chargeCodeLabel }: IProps) => {
  const [editChargeCode, setEditChargeCode] = useState(chargeCodeValue === '');

  const handleSubmit = () => {
    updateChargeCode();
    setEditChargeCode(false);
  };

  const chargeCodeId = chargeCodeLabel.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? '-' : '') + $.toLowerCase()
  );

  const label = (
    <>
      <CoverageValue id={chargeCodeId}>
        {chargeCodeLabel}
        <Tooltip icon="question-mark">
          <TooltipText>
            When set, this overrides the default charge code used
            when writing charge ledger entries. This value will be
            provided by the partner, and attempts to write charge
            ledger entries will fail when not set correctly.
            <h2><BoldText>Requires:</BoldText></h2>
            <ul>
              <li>Ledger Sync enabled</li>
              <li>Create Charge? enabled</li>
            </ul>
          </TooltipText>
        </Tooltip>
      </CoverageValue>
    </>
  );

  return (
    <>
      {editChargeCode ? (
        <DisplayField>
          { label }
          <TextInput
            id={`${chargeCodeId}-input`}
            data-cy="chargeCode"
            name={chargeCodeId}
            value={chargeCodeValue}
            onChange={(event) => setChargeCode(event.target.value)}
            style={{ display: 'flex', alignItems: 'center' }}
          />
          <ButtonHabitat
            id={`${chargeCodeId}-submit`}
            type="submit"
            usage="primary"
            variant="interaction"
            disabled={false}
            onClick={handleSubmit}
            size="small"
          >
            Submit
          </ButtonHabitat>
        </DisplayField>
      ) : (
        <DisplayField>
          { label }
          <DisplayFieldControls>
            <ActionButton
              id="policyPush"
              variant="noLabel"
              usage="quiet"
              icon="edit"
              tooltip={`Edit ${chargeCodeLabel}`}
              onClick={() => setEditChargeCode(true)}
            />
            {chargeCodeValue}
          </DisplayFieldControls>
        </DisplayField>
      )}
    </>
  );
};

export default ChargeCode;
