import { ButtonHabitat, Checkbox, TextInput } from '@sayrhino/rhino-shared-js';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { StyledH5 } from '../Filters/ClaimFilterStyles';
import { ICoverageRule, IPropertySearchResult } from '../interfaces';
import AddCoverageRule from './AddCoverageRule';
import CoverageRuleCard from './CoverageRuleCard';
import { StyledCoverageRulesBorder } from './styles/CoverageRuleSwitchStyles';
import { ICoverageRuleSwitchNew } from './interface';
import PropertyMultiSelect from './PropertyMultiSelect';
import Spacer from './SpacingTokens';
import { ErrorText, StyledSaveButtonWrapper } from './styles/AddCoverageRuleStyles';
import {
  ButtonSubP,
  FormInputSectionSubtitle,
  FormInputSectionTitle,
  StyledCheckboxPadding,
  StyledH6
} from './styles/CoverageRuleSwitchStyles';
import {
  IRulesetData,
  realpageScreening,
  yardiRenterTypes,
  realpageRenterTypes,
  yardiCreditCheckStatus,
  yardiScreening,
  entrataScreening
} from './util/rulesetConstant';

const CoverageRuleSwitchNew = ({
  id,
  index,
  showRulesetNameInput = true,
  integration,
  label,
  setCustomRulesets,
  customRulesets,
  coverageRules,
  addInviteCriteria,
  ruleset,
  partnerRulesetError,
  setPartnerRulesetsError,
  showApplicableProperties = true,
  closeEditRuleset,
  toggleExpandStatus
}: ICoverageRuleSwitchNew) => {
  const screening = integration === 'yardi' ? yardiScreening : integration === 'realpage' ? realpageScreening : entrataScreening;
  const renterTypes = integration === 'yardi' ? yardiRenterTypes : realpageRenterTypes;
  const [selectedScreening, setSelectedScreening] = useState<string[]>(
    ruleset?.invite_criteria?.allowed_screening_results || []
  );
  const [selectedCreditCheckStatus, setSelectedCreditCheckStatus] = useState<string[]>(
    ruleset?.invite_criteria?.allowed_credit_check_statuses || []
  );
  const [moveInDateRule, setMoveInDateRule] = useState<boolean>(
    ruleset?.invite_criteria?.use_renter_approval_date || false
  );
  const [showCoverageRule, setShowCoverageRule] = useState<boolean>(false);
  const [coverageRuleList, setCoverageRuleList] = useState<ICoverageRule[]>(ruleset?.coverage_rules || []);
  const [rulesetName, setRulesetName] = useState<string>(ruleset?.name || '');
  const [selectedProperties, setSelectedProperties] = useState<{ value: IPropertySearchResult[]; error: string }>({
    value: ruleset?.properties || [],
    error: ''
  });

  const formatFieldId = (value: string) => value.replace(/ /g, '-');

  const screeningHandleChange = (screening: string) => {
    if (selectedScreening.includes(screening)) {
      setSelectedScreening((prev) => prev.filter((x) => x !== screening));
    } else {
      setSelectedScreening((prev) => [...prev, screening]);
    }
  };

  const screeningChecked = (screening: string) => {
    return selectedScreening.includes(screening);
  };

  const creditCheckStatusChange = (creditCheckStatus: string) => {
    if (selectedCreditCheckStatus.includes(creditCheckStatus)) {
      setSelectedCreditCheckStatus((prev) => prev.filter((x) => x !== creditCheckStatus));
    } else {
      setSelectedCreditCheckStatus((prev) => [...prev, creditCheckStatus]);
    }
  };

  const creditCheckStatusChecked = (creditCheckStatus: string) => {
    return selectedCreditCheckStatus.includes(creditCheckStatus);
  };

  const saveCoverageRuleList = (ruleList: ICoverageRule[]) => {
    setCoverageRuleList(ruleList);
    if (label === 'Partner') {
      coverageRules(ruleList);
    }
    setShowCoverageRule(false);
  };

  const removeCoverage = (coverageRule: ICoverageRule) => {
    const filteredList = coverageRuleList.filter((rule) => rule !== coverageRule);
    setCoverageRuleList(filteredList);
    if (label === 'Partner') {
      coverageRules(filteredList);
    }
  };

  const getUnusedRenterTypes = () => {
    if (coverageRuleList.length > 0) {
      return renterTypes.filter(renterType =>
        coverageRuleList.every(rule => rule.coverage_option_name !== renterType)
      );
    }
    return renterTypes;
  };

  const handleRulesetName = (e: ChangeEvent<HTMLInputElement>) => {
    setRulesetName(e.target.value);
  };

  const isInviteExclusion = () => {
    const eligible = selectedCreditCheckStatus.length || selectedScreening.length || moveInDateRule;
    return selectedProperties.value.length && !eligible && coverageRuleList.length === 0 && rulesetName;
  }

  const saveButtonDisabled = () => {
    if(isInviteExclusion()) return false;
    return coverageRuleList.length === 0 || !rulesetName || selectedProperties.value.length === 0;
  }

  const saveCustomRulesets = () => {
    setCustomRulesets!({
      ruleset: {
        name: rulesetName,
        properties: selectedProperties.value || [],
        type: 'property',
        coverageable_ids: selectedProperties.value?.map(({ id }) => id.toString())!,
        coverage_rules: coverageRuleList,
        invite_criteria: {
          use_renter_approval_date: moveInDateRule,
          allowed_screening_results: selectedScreening,
          allowed_credit_check_statuses: selectedCreditCheckStatus
        },
        default: false
      },
      index,
      closeEditRuleset: closeEditRulesetAndToggleExpandStatus
    });
  };

  const closeEditRulesetAndToggleExpandStatus = () => {
    toggleExpandStatus(id, false);
    if(closeEditRuleset != undefined) closeEditRuleset();
  }

  const toggleCoverageRule = () => {
    setShowCoverageRule(true);
    toggleExpandStatus(`${id}-newCoverageRule`, true);
    if (label === 'Partner') {
      setPartnerRulesetsError({ ...partnerRulesetError, coverage_rules: '' });
    }
  };

  useEffect(() => {
    if (label === 'Partner') {
      addInviteCriteria({
        screening: selectedScreening,
        userRenter: moveInDateRule,
        creditCheckStatus: selectedCreditCheckStatus
      });
    }
  }, [selectedCreditCheckStatus, selectedScreening, moveInDateRule]);

  return (
    <Fragment>
      {showRulesetNameInput && (
        <TextInput
          name="ruleset"
          label="Ruleset name"
          value={rulesetName}
          id="rulesetName"
          onChange={handleRulesetName}
        />
      )}
      <StyledH5>Rhino Product Eligibility</StyledH5>
      <ButtonSubP>Which renter should be considered eligible for Rhino products?</ButtonSubP>
      <Spacer />
      <StyledCoverageRulesBorder>
        <StyledH6>Screening Result</StyledH6>
        <StyledCheckboxPadding>
          {screening.map((item: IRulesetData, index: number) => (
            <Checkbox
              label={item.name}
              id={`${label}-${formatFieldId(item.value)}-${index}`}
              key={`${formatFieldId(item.value)}-${index}`}
              checked={screeningChecked(item.value)}
              onCheckedChange={() => screeningHandleChange(item.value)}
            />
          ))}
        </StyledCheckboxPadding>
        {integration === 'yardi' && (
          <>
            <StyledH6>Credit Check Status</StyledH6>
            <StyledCheckboxPadding>
              {yardiCreditCheckStatus.map((item: IRulesetData, index: number) => (
                <Checkbox
                  label={item.name}
                  id={`${label}-${formatFieldId(item.value)}_${label}_${index}`}
                  checked={creditCheckStatusChecked(item.value)}
                  key={`${formatFieldId(item.value)}${index}`}
                  onCheckedChange={() => creditCheckStatusChange(item.value)}
                />
              ))}
            </StyledCheckboxPadding>
            <Checkbox
              label="Resident has been approved by partner user"
              id={`moveInDateRule-${label}${index ? index : ''}`}
              checked={moveInDateRule}
              onCheckedChange={() => setMoveInDateRule(!moveInDateRule)}
            />
          </>
        )}
      </StyledCoverageRulesBorder>

      <hr />
      <StyledH5> Coverage Amounts</StyledH5>
      <ButtonSubP>How much coverage should Rhino request?</ButtonSubP>
      <Spacer />

      {coverageRuleList.map((item, index) => (
        <CoverageRuleCard
          key={`coverage-rule-${index}`}
          id={`${id}-coverage-rule-${index}`}
          editCoverage={item}
          getUnusedRenterTypes={getUnusedRenterTypes}
          integration={integration}
          saveCoverageRuleList={saveCoverageRuleList}
          removeCoverage={removeCoverage}
          coverageRuleList={coverageRuleList}
          toggleExpandStatus={toggleExpandStatus}
        />
      ))}
      {showCoverageRule && getUnusedRenterTypes().length > 0 && (
        <StyledCoverageRulesBorder>
          <AddCoverageRule
            getUnusedRenterTypes={getUnusedRenterTypes}
            setShowCoverageRule={setShowCoverageRule}
            integration={integration}
            saveCoverageRuleList={saveCoverageRuleList}
            coverageRuleList={coverageRuleList}
            toggleExpandStatus={toggleExpandStatus}
            id={`${id}-newCoverageRule`}
          />
        </StyledCoverageRulesBorder>
      )}

      <Spacer amount={3} />
      <ButtonHabitat
        id="new-ruleset-button"
        aria-label="Add coverage amount"
        variant="interaction"
        size="small"
        usage="secondary"
        disabled={showCoverageRule || getUnusedRenterTypes().length === 0}
        onClick={toggleCoverageRule}
      >
        Add coverage amount
      </ButtonHabitat>
      {label === 'Partner' && partnerRulesetError.coverage_rules && (
        <ErrorText>{partnerRulesetError.coverage_rules}</ErrorText>
      )}
      <hr />
      {showApplicableProperties && (
        <>
          <FormInputSectionTitle>Applicable properties</FormInputSectionTitle>
          <FormInputSectionSubtitle>
            Please select the properties that this ruleset applies to.
          </FormInputSectionSubtitle>
          <PropertyMultiSelect
            id={`ruleset-property-select`}
            selectedProperties={selectedProperties?.value}
            onChange={(value) => setSelectedProperties({ value, error: '' })}
            error={selectedProperties.error}
          />
        </>
      )}
      {showRulesetNameInput && (
        <StyledSaveButtonWrapper>
          <ButtonHabitat
            id="save-coverage-rule"
            variant="interaction"
            size="small"
            usage="primary"
            disabled={saveButtonDisabled()}
            onClick={() => {
              saveCustomRulesets();
            }}
          >
            Save
          </ButtonHabitat>
        </StyledSaveButtonWrapper>
      )}
    </Fragment>
  );
};

export default CoverageRuleSwitchNew;
