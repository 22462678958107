import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ButtonHabitat,
  CloseIcon,
  FONTS,
  FONT_FAMILY,
  Loading,
  PALETTE,
  Switch,
} from '@sayrhino/rhino-shared-js';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { CoverageTypeEnum, FormMode } from '../interfaces';
import CustomCoverageRuleCard from './CustomCoverageRuleCard';
import CustomCoverageRulesForm, { StyledCoverageRulesBorder } from './CustomCoverageRulesForm';
import SectionHeader from './SectionHeader';
import Spacer from './SpacingTokens';
import { StyledSectionSubheader } from './Styled';
import { usePartnerIntegrationForm } from './usePartnerIntegrationForm';
import { StyledValidationErrorBanner } from '../PropertyOwners/Styled';
import { LoadingWrapper } from '../Claims/ClaimDetails/ClaimStyles';
import usePartnerCoverageRules from 'api/v2/usePartnerCoverageRules';
import { useParams } from 'react-router-dom';
import usePartnerIntegration from 'api/v2/usePartnerIntegration';
import usePropertyCoverageRules from 'api/v2/usePropertyCoverageRules';
import Label from 'components/common/formsy/Label';
import { CreateCoverageRulesFormNew } from './CoverageRulesFormNew';
import useCoverageRulesForm from './useCoverageRulesForm';
import { MAX_COVERAGE_AMOUNT } from './CustomCoverageRulesForm';
import CoverageRuleForm from './CoverageRuleForm';

const contextParaCSS = css({
  color: PALETTE.brand100,
  padding: '12px 0'
});

const contextHeadingCSS = css({
  color: PALETTE.brand100
});
const contextLineCSS = css({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutral12}`,
  margin: '1em 0 1em -48px',
  paddingBottom: 32,
  width: '384px'
});
const numberCircleCSS = css({
  border: `1px solid ${PALETTE.brand100}`,
  borderRadius: '50%',
  height: '28px',
  width: '28px',
  display: 'inline-block',
  textAlign: 'center',
  color: PALETTE.brand100,
  marginRight: 14,
  padding: 1
});

const InfoRailDiv = styled.div({
  height: '100vh',
  display: 'flex',
  position: 'fixed',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'flex-start',
  background: PALETTE.brand4,
  padding: 48,
  borderRight: `1px solid ${PALETTE.neutral12}`,
  width: '384px'
});
const IntegrationSetupPageDiv = styled.div({ display: 'flex' });

const InfoRailHeader = styled.h3(FONTS.h3, { color: PALETTE.brand100 });
const InfoRailSubHeader = styled.span(FONTS.p2, contextParaCSS, {
  paddingBottom: 40
});
const closeButtonCSS = css({
  background: PALETTE.neutral4,
  borderRadius: '20px',
  height: '32px',
  width: '32px',
  textAlign: 'center',
  border: 'none'
});
const InfoRailStepContainer = styled.div({ marginBottom: 50 });
const InfoRailStepNumber = styled.div(FONTS.p2, numberCircleCSS);
const InfoRailStepText = styled.span(FONTS.h5, contextHeadingCSS);
const PMSIntegrationSetupDivider = styled.hr({ contextLineCSS });
const IntegrationFormDiv = styled.div({ flex: 1, marginLeft: 384 });
const FormContainer = styled.div({ padding: '40px 96px', marginBottom: 100, maxWidth: '900px' });
const FormHeaderSpan = styled.span(FONTS.p1, FONT_FAMILY.extendedMedium, {
  color: PALETTE.neutral65
});
const FormHeaderCloseButton = styled.button(closeButtonCSS);
const StyledFormHeaderCloseIcon = styled(CloseIcon)({ marginTop: '7px', color: PALETTE.neutral55 });
const FormHeaderDiv = styled.div({
  backgroundColor: PALETTE.neutralLight,
  padding: '20px 48px',
  position: 'sticky',
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 1
});
const PMSIntegrationSetupHeader = styled.h3(FONTS.h3);
const StyledSubmitButton = styled(ButtonHabitat)({
  float: 'right'
});
const StyledVErrorBanner = styled(StyledValidationErrorBanner)({ marginTop: 0 });
const StyledIntegrationRule = styled.h4(FONTS.h4);
const CopyWrapper = styled.div({ paddingTop: '8px', paddingBottom: '12px' });
const StyledCopySubP = styled.p(FONT_FAMILY.extendedLight, {
  lineHeight: '24px',
  color: PALETTE.neutral88
});
const StyledCopySubPWithPadding = styled(StyledCopySubP)(FONT_FAMILY.extendedLight, {
  paddingTop: '16px',
  paddingBottom: '8px',
  lineHeight: '24px',
  color: PALETTE.neutral88
});
const StyledLabel = styled(Label)(FONT_FAMILY.extendedMedium, { color: PALETTE.neutral88, lineHeight: '24px' });
const ConditionHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between'
});

const CreateCoverageRulesForm = () => {
  const navigate = useNavigate();
  const { raiseCoverageErrors } = useCoverageRulesForm();
  const {
    coverageRule,
    conditionalCoverageRule,
    customRuleCoverage,
    customRuleSetErrors,
    handleErrorReset,
    handleSwitchSelect,
    handleCashSwitchSelect,
    handleConditionalSwitchSelect,
    handleConditionalCashSwitchSelect,
    showPartnerRulesets,
    setShowPartnerRulesets,
    showCustomRulesets,
    setShowCustomRulesets,
    createCoverageRules,
    integration
  } = usePartnerIntegrationForm();

  const getCoverageRuleSetUsed = (ruleSet: string | undefined) => {
    if (ruleSet === 'Generic') return `ISS - ${ruleSet}`;
    if (ruleSet === 'ScreeningResult' || ruleSet === 'Baseline') return `Fallback- ${ruleSet}`;
    return ruleSet;
  };

  const coverageRulesInvalid = () => {
    const coverageAttributesToValidate: [type: CoverageTypeEnum, value: number][] = [
      [coverageRule.coverageType, coverageRule.coverageValue],
      [conditionalCoverageRule.coverageType, conditionalCoverageRule.coverageValue]
    ];
    const cashAttributesToValidate: [type: CoverageTypeEnum, value: number, sameCash: boolean][] = [
      [coverageRule.coverageCashType, coverageRule.coverageCashValue, coverageRule.coverageSameCashDeposit],
      [
        conditionalCoverageRule.coverageCashType,
        conditionalCoverageRule.coverageCashValue,
        conditionalCoverageRule.coverageSameCashDeposit
      ]
    ];

    return (coverageAttributesToValidate.some(([type, value]) =>
      (type === 'amount_cents' || type === 'multiplier') && (value <= 0 || value > MAX_COVERAGE_AMOUNT)
    )) || (cashAttributesToValidate.some(([type, value, sameCash]) =>
      !sameCash && (type === 'amount_cents' || type === 'multiplier') && (value <= 0 || value > MAX_COVERAGE_AMOUNT)
    ));
  }

  const prepareCashValuesAndCreateRules = () => {
    if (coverageRule.coverageSameCashDeposit) {
      coverageRule.setCoverageCashType(coverageRule.coverageType);
      coverageRule.setCoverageCashValue(coverageRule.coverageValue);
    };
    if (conditionalCoverageRule.coverageSameCashDeposit) {
      conditionalCoverageRule.setCoverageCashType(conditionalCoverageRule.coverageType);
      conditionalCoverageRule.setCoverageCashValue(conditionalCoverageRule.coverageValue);
    };
    createCoverageRules();
  };

  const handleSubmit = () => {
    if (coverageRulesInvalid()) {
      raiseCoverageErrors();
    } else {
      prepareCashValuesAndCreateRules();
    };
  };

  useEffect(()=> {

  },[])

  return (
    <IntegrationSetupPageDiv>
      <InfoRailDiv>
        <InfoRailHeader>PMS Integration Setup</InfoRailHeader>
        <InfoRailSubHeader>Follow these instructions to set up your PMS integration.</InfoRailSubHeader>
        <PMSIntegrationSetupDivider />
        <InfoRailStepContainer>
          <InfoRailStepNumber>1</InfoRailStepNumber>
          <InfoRailStepText>Select your PMS</InfoRailStepText>
        </InfoRailStepContainer>
        <PMSIntegrationSetupDivider />
        <div>
          <InfoRailStepNumber>2</InfoRailStepNumber>
          <InfoRailStepText>Add Integration details</InfoRailStepText>
        </div>
      </InfoRailDiv>
      <IntegrationFormDiv>
        <FormHeaderDiv>
          <FormHeaderSpan>PMS Integration Setup</FormHeaderSpan>
          <FormHeaderCloseButton onClick={() => navigate(-1)} aria-label="Leave integration form">
            <StyledFormHeaderCloseIcon height="16px" width="16px" />
          </FormHeaderCloseButton>
        </FormHeaderDiv>
        <FormContainer>
          <Spacer amount={4} />
          <PMSIntegrationSetupHeader>Coverage rules and Rhino product eligibility</PMSIntegrationSetupHeader>
          <Spacer amount={5} />
          <SectionHeader
            action={
              <Switch
                aria-label={`Toggle partner coverage rules`}
                id={'coverage-toggle-partner'}
                checked={showPartnerRulesets}
                onCheckedChange={() => setShowPartnerRulesets(!showPartnerRulesets)}
              ></Switch>
            }
          >
            Partner ruleset
          </SectionHeader>
          <Spacer amount={3} />

          <StyledSectionSubheader>
            Partner rulesets apply to all properties assigned to this partner, unless a custom ruleset has
            been applied to certain properties.
          </StyledSectionSubheader>
          <Spacer amount={4} />

          <AnimatePresence initial={false}>
            {showPartnerRulesets && (
              <motion.section
                initial={{ opacity: 0.1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <StyledCoverageRulesBorder>
                  <>
                    <div>
                      <div>
                        <StyledLabel>Partner rule for approvals</StyledLabel>
                        <StyledCopySubP>
                          Please enter the security deposit coverage that would be required for an approved renter.
                        </StyledCopySubP>
                      </div>

                      <CoverageRuleForm
                        coverageRule={coverageRule}
                        coverageLabel="Partner rule for approvals"
                        coverageCashLabel="Partner cash rule for approvals"
                        coverageId="default-approval"
                        handleSwitchSelect={handleSwitchSelect}
                        handleCashSwitchSelect={handleCashSwitchSelect}
                      />

                      <Spacer amount={3} />
                    </div>

                    <div>
                      <div>
                        <StyledLabel>Partner rule for approvals with conditions</StyledLabel>
                        <StyledCopySubP>
                          {
                            "Please enter the security deposit coverage that would be required for \
                            a conditionally approved renter."
                          }
                        </StyledCopySubP>
                      </div>

                      <CoverageRuleForm
                        coverageRule={conditionalCoverageRule}
                        coverageLabel="Partner rule for approvals with conditions"
                        coverageCashLabel="Partner cash rule for approvals with conditions"
                        coverageId="default-approval-conditions"
                        handleSwitchSelect={handleConditionalSwitchSelect}
                        handleCashSwitchSelect={handleConditionalCashSwitchSelect}
                      />
                    </div>
                  </>
                </StyledCoverageRulesBorder>
                <Spacer amount={4} />
              </motion.section>
            )}
          </AnimatePresence>

          <SectionHeader
            action={
              <Switch
                aria-label={`Toggle custom coverage rules`}
                id={'coverage-toggle-custom'}
                checked={showCustomRulesets}
                onCheckedChange={() => setShowCustomRulesets(!showCustomRulesets)}
              ></Switch>
            }
          >
            Custom rulesets
          </SectionHeader>
          <Spacer amount={3} />

          <StyledSectionSubheader>
            Custom rulesets allow the partner to select the properties that are eligible for Rhino products.
            <br/>
            Custom rulesets will override partner rulesets.
          </StyledSectionSubheader>
          <Spacer amount={3} />

          <AnimatePresence initial={false}>
            {showCustomRulesets && (
              <motion.section
                initial={{ opacity: 0.1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                {customRuleCoverage.customRuleSet.map((ruleset, index) => {
                  const ruleFinder = (coverageOptionName: string, conditionType: string | undefined = undefined) => {
                    return ruleset.coverage_rules.find((rule) =>
                      rule.coverage_option_name === coverageOptionName &&
                      (!conditionType || rule.condition_type === conditionType)
                    );
                  };
                  const approvalRule = ruleFinder('Approval', undefined);
                  const approvalConditionsRule = ruleFinder('Approval with Conditions', undefined);
                  const approvalConditionsGTDepositRule = ruleFinder('Approval with Conditions', 'deposit_gt_threshold');
                  const approvalConditionsLTEDepositRule = ruleFinder('Approval with Conditions', 'deposit_lte_threshold');

                  return (
                    <CustomCoverageRuleCard
                      key={index}
                      {...ruleset}
                      index={index}
                      rulesetName={ruleset.name}
                      rulesetDepositThresholdCents={ruleset.deposit_threshold_cents}
                      approvalRule={approvalRule!}
                      approvalConditionsRule={approvalConditionsRule!}
                      approvalConditionsGTDepositRule={approvalConditionsGTDepositRule!}
                      approvalConditionsLTEDepositRule={approvalConditionsLTEDepositRule!}
                      properties={ruleset.properties}
                      handleRemoveRuleSet={() => customRuleCoverage.handleRemoveRuleSet(index)}
                      handleUpdateRuleset={(value) => customRuleCoverage.handleEditRuleset(value, index)}
                      error={customRuleSetErrors[index]}
                      handleErrorReset={() => handleErrorReset(index)}
                      id={ruleset.id}
                    />
                  )
                })}

                {customRuleCoverage.showForm && (
                  <>
                    <CustomCoverageRulesForm
                      index={customRuleCoverage.ruleSetLength}
                      key={'new-form'}
                      handleAddOrUpdateRuleset={customRuleCoverage.handleAddNewRuleSet}
                      mode={FormMode.CREATE}
                    />
                    <Spacer amount={3} />
                  </>
                )}

                <ButtonHabitat
                  id="new-ruleset-button"
                  variant="interaction"
                  aria-label="Add coverage amount"
                  size="small"
                  usage="secondary"
                  disabled={customRuleCoverage.showForm}
                  onClick={() => customRuleCoverage.setShowForm(true)}
                >
                  Add custom ruleset
                </ButtonHabitat>
                <Spacer amount={4} />
              </motion.section>
            )}
          </AnimatePresence>

          <CopyWrapper>
            <StyledCopySubPWithPadding>
              This integration will be using the the following ruleset to determine resident eligibility:
            </StyledCopySubPWithPadding>
            {(integration?.rule_set === 'ScreeningResult' || integration?.rule_set === 'Baseline') && (
              <StyledVErrorBanner>This integration is not using ISS or hardcoded coverage rule set.</StyledVErrorBanner>
            )}
            <StyledIntegrationRule>{getCoverageRuleSetUsed(integration?.rule_set)}</StyledIntegrationRule>
          </CopyWrapper>

          <StyledSubmitButton
            id="coverage-form-submit"
            type="submit"
            usage="primary"
            variant="interaction"
            onClick={handleSubmit}
          >
            Submit
          </StyledSubmitButton>
        </FormContainer>
      </IntegrationFormDiv>
    </IntegrationSetupPageDiv>
  );
};

export const CoverageRulesForm = () => {
  const { partnerIntegrationId, propertyOwnerId } = useParams();
  const { integration } = usePartnerIntegrationForm();

  const { isSuccess } = usePartnerIntegration(partnerIntegrationId) ?? {};
  const { isSuccess: isCoverageSuccess } = usePartnerCoverageRules(partnerIntegrationId) ?? {};
  const { isSuccess: isPropertyCoverageSuccess } = usePropertyCoverageRules(propertyOwnerId) ?? {};

  return isSuccess && isCoverageSuccess && isPropertyCoverageSuccess ? (
    <>{integration?.type !== 'realpage' ? <CreateCoverageRulesFormNew /> : <CreateCoverageRulesForm />}</>
  ) : (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};

export default CoverageRulesForm;
