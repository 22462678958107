import React from 'react';
import { DisplayFieldControls, PropertyOwnerFormLabel, StyledContent } from './Styled';
import { Switch } from '@sayrhino/rhino-shared-js';
import useUpdatePropertyOwnerMutation from 'api/v2/updatePropertyOwner';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import useToast from '../toast/use-toast';
import { AxiosError } from 'axios';
import { Tooltip, TooltipText } from '../Tooltip';

interface IProps {
  depositAccountsEnabled?: boolean;
}

const DepositAccountsSection = ({ depositAccountsEnabled }: IProps) => {
  const { propertyOwnerId } = useParams();
  const { mutate: updatePropertyOwnerMutation } = useUpdatePropertyOwnerMutation();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const update = (propertyOwnerId: number, depositAccountsEnabled: boolean) => {
    updatePropertyOwnerMutation(
      { id: propertyOwnerId, deposit_accounts_enabled: depositAccountsEnabled },
      {
        onSuccess: (_e) => {
          queryClient.invalidateQueries(['property owner details', propertyOwnerId]);
          addToast('Property Owner Information was successfully updated');
        },
        onError: (_e: AxiosError) => {
          addToast('Something went wrong', 'error');
        }
      }
    );
  };

  return (
    <>
      <PropertyOwnerFormLabel>
        <div>
          Partner-held Deposits
          <Tooltip icon="question-mark">
            <TooltipText>
              When enabled, cash deposits will be held in partner accounts.
              This requires properties to be correctly configured with Stripe
              Bank Accounts through either bulk CSV upload or by PMAs via the
              Deposit Accounts tab. This toggle enables PMAs to view the Deposit Accounts
              tab within their Portal experience.
            </TooltipText>
          </Tooltip>
        </div>
        <DisplayFieldControls>
          <Switch
            aria-labelledby="enable-deposit-accounts"
            aria-selected={!!depositAccountsEnabled}
            checked={!!depositAccountsEnabled}
            onCheckedChange={() => update(Number(propertyOwnerId), !depositAccountsEnabled)}
            id="toggle-deposit-accounts"
          />
        </DisplayFieldControls>
      </PropertyOwnerFormLabel>
      <StyledContent>
        Toggling this feature will allow this property owner to access Deposit Accounts within their Portal experience.
      </StyledContent>
    </>
  );
};

export default DepositAccountsSection;
