import React from 'react';
import { FONTS, PALETTE, FONT_FAMILY } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import TooltipInfoIcon from './TooltipInfoIcon';
import TooltipQuestionIcon from './TooltipQuestionIcon';

const StyledTooltip = styled(ReactTooltip)({
  padding: '12px !important',
  border: `1px solid ${PALETTE.neutral12} !important`,
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16) !important',
  borderRadius: '8px !important',
  opacity: '10 !important',
  width: '238px !important',
  '&:hover': {
    visibility: 'visible'
  }
});
const TooltipIconContainer = styled.div({
  display: 'inline-block',
  marginLeft: '5px',
  'svg:hover': {
    color: PALETTE.interaction100
  },
  verticalAlign: 'middle'
});
const TooltipContainer = styled.div({ display: 'inline-block', cursor: 'help' });
interface TooltipProps {
  message?: string,
  id?: string,
  icon?: 'question-mark' | 'info',
  place?: 'top' | 'right' | 'bottom' | 'left',
}

const TooltipText = styled.span([[FONTS.p3]]);

const Tooltip: React.FC<TooltipProps> = ({ message = "", id, icon = 'info', place = 'bottom', children }) => {

  const tooltipId = (() => {
    if (id) return id;
    return `tooltip-id-${ Math.random() }`;
  })();

  const tooltipIcon = (() => {
    const props = { "data-tip": true,  "data-for": tooltipId };

    switch(icon) {
      case "question-mark":
        return <TooltipQuestionIcon {...props} />;
      case "info":
        return <TooltipInfoIcon {...props} />;
    }
  })();

  return (
    <TooltipContainer>
      <TooltipIconContainer>
        { tooltipIcon }
      </TooltipIconContainer>
      <StyledTooltip id={ tooltipId } type="light" effect="solid" place={ place } delayHide={1000} clickable={true} >
        {
          message && (
            <TooltipText>
              { message }
            </TooltipText>
          )
        }
        { children ? children : null }
      </StyledTooltip>
    </TooltipContainer>
  );
}

export default Tooltip;
export { Tooltip, TooltipText };