import { Loading, Switch, ActionButton, ActionLink, Checkbox, FONTS } from '@sayrhino/rhino-shared-js';
import { useMutation, useQueryClient } from 'react-query';
import usePartnerIntegration, { PARTNER_INTEGRATION_KEY } from 'api/v2/usePartnerIntegration';
import validatePartnerIntegration from 'api/v2/validatePartnerIntegration';
import React, { useState } from 'react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { PARTNER_INTEGRATION_ROUTES } from '../utils/routeHelpers';
import { useUserRole } from '../utils/userRole';
import {
  CoverageLabel,
  CoverageValue,
  RenterLink,
  Subtitle,
  StyledContent,
  IndentedCoverageValue,
  LoadingWrapper,
  DisplayField,
  FlexButtonContainer,
  IntegrationButton,
  DisplayFieldControls,
  Platform,
  BoldText,
  AttachmentLabel,
  PropertyOwnerFormLabel,
  NumericTextInput,
  FieldError
} from './Styled';
import {
  IRealPageIntegration,
  IYardiIntegration,
  IYardiIntegrationPartnerableAttributes,
  PartnerIntegrationStatusEnum,
  PartnerValidationStatus
} from '../interfaces';
import useIntegrationDetails from './useIntegrationDetails';
import { PROPERTY_OWNERS_CACHE_KEY } from 'api/v2/usePropertyOwners';
import ValidationStatus from './validationStatus';
import { getLocal, removeLocal } from '../utils/localStorage';
import WarningModal from '../Integrations/WarningModal';
import ChargeCode from './ChargeCode';
import { useDropzone } from 'react-dropzone';
import axios, { AxiosError } from 'axios';
import { csrfToken } from 'utils/document';
import AttachmentFileItem from '../AttachmentFileItem';
import useToast, { TOAST_STATUS } from '../toast/use-toast';
import { PullPropertiesParams, usePullPropertiesMutation } from './pullProperties';
import { Tooltip, TooltipText } from '../Tooltip';

type RequestError = {
  errors: { description: string[] };
};
interface IProps {
  partnerIntegrationId: string;
  integration: IRealPageIntegration | IYardiIntegration;
  logo: any;
}

const IntegrationControls: React.FC<IProps> = ({ partnerIntegrationId, integration, logo }) => {
  const navigate = useNavigate();
  const { propertyOwnerId } = useParams();
  const {
    dataPullEnabled,
    autoInviteEnabled,
    immediateConditionalInvitesEnabled,
    policyPushEnabled,
    cashDepositPushEnabled,
    updateDataPull,
    updateAutoInvite,
    updateImmediateConditionalInvites,
    updatePolicyPush,
    updateCashDepositPush,
    disablePolicyAndCashDepositPushSwitch,
    isUpdatePartnerLoading,
    showAutoInviteWarning,
    shouldToggleAutoInvite,
    closeWarningModal,
    updateLedgerSync,
    updateNativeEnrollment,
    nativeEnrollmentEnabled,
    showSdiCoverageAmount,
    updateShowSdiCoverageAmount,
    updateHealthCheck,
    healthCheckEnabled,
    ledgerSyncEnabled,
    updateLedgerSyncCreateCharge,
    ledgerSyncCreateCharge,
    ledgerSyncTaggedTransactions,
    updateLedgerSyncTaggedTransactions,
    writeToDepositMemo,
    updateWriteToDepositMemo,
    depositChargeCode,
    setDepositChargeCode,
    updateDepositChargeCode,
    updateYardiChargeCode
  } = useIntegrationDetails(integration);
  const { isAdmin } = useUserRole();
  const queryClient = useQueryClient();
  const depositMemoEnabled = (window as any).App?.featureFlags?.depositMemoEnabled;
  const { mutate: validatePartnerIntegrationMutation, isLoading: isValidatePartnerIntegrationLoading } = useMutation(
    validatePartnerIntegration,
    {}
  );
  const [attachmentFiles, setAttachmentFiles] = useState<any[]>([logo].filter(Boolean));
  const [isLoading, setLoading] = useState<boolean>(false);
  const { mutate: pullProperties } = usePullPropertiesMutation();
  const authenticity_token = csrfToken();
  const { addToast } = useToast();
  const editPath = generatePath(PARTNER_INTEGRATION_ROUTES.edit, {
    partnerIntegrationId,
    propertyOwnerId
  });
  const linkWithState = `${editPath}?isEnabledLedgerSync=${ledgerSyncEnabled}`;
  const [prospectSyncDays, setProspectSyncDays] = useState<number>(1);

  const onPullProperties = async () => {
    pullProperties(
      { propertyOwnerId: propertyOwnerId!, prospectSyncDays: prospectSyncDays },
      {
        onSuccess: (res) => {
          addToast('Pull properties process started', TOAST_STATUS.SUCCESS);
        },
        onError: (err: AxiosError<RequestError>) => {
          addToast('Error pulling properties', TOAST_STATUS.ERROR);
        }
      }
    );
  };

  let yardiAttributes: IYardiIntegrationPartnerableAttributes | undefined;
  if (integration.type === 'yardi')
    yardiAttributes = (integration as IYardiIntegration).integration_partnerable_attributes;

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((file, index) => {
        const data = new FormData();
        data.append('property_owner[logo]', file);
        axios
          .put(`/v2/admin/property_owners/${integration.property_owner_id}`, data, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': authenticity_token
            },
            onUploadProgress: () => {
              setLoading(true);
            }
          })
          .then((res) => {
            setAttachmentFiles([res.data.logo]);
            setLoading(false);
          })
          .catch(() => {
            addToast('Could not save attachment', 'error');
          });
      });
    },
    multiple: false
  });

  const removeAttachment = (e, file) => {
    e.preventDefault();
    axios
      .put(
        `/v2/admin/property_owners/${integration.property_owner_id}/delete_logo_attached`,
        {},
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': authenticity_token
          }
        }
      )
      .then((res) => {
        setAttachmentFiles((currentFiles) => currentFiles.filter((f) => f.name !== file.name));
      })
      .catch((err) => {
        addToast('Could not remove attachment', 'error');
      });
  };

  const onValidatePartnerIntegration = () => {
    validatePartnerIntegrationMutation(integration?.uuid, {
      onSuccess: () => {
        queryClient.invalidateQueries([PARTNER_INTEGRATION_KEY, partnerIntegrationId]);
        queryClient.invalidateQueries([PROPERTY_OWNERS_CACHE_KEY]);
      }
    });
  };

  const validationProcessingStatuses = [PartnerIntegrationStatusEnum.WAITING, PartnerIntegrationStatusEnum.IN_PROGRESS];
  const integrationValidations = integration.integration_validation;

  const credentialCheckStatus: PartnerValidationStatus | undefined = integrationValidations.credential_check_status;
  const credentialValidationStatusLabel = (): string | undefined => {
    if (validationProcessingStatuses.includes(credentialCheckStatus)) {
      return 'In progress';
    } else if (credentialCheckStatus === PartnerIntegrationStatusEnum.ERROR) {
      return 'Invalid credentials';
    } else if (credentialCheckStatus === PartnerIntegrationStatusEnum.FAILED) {
      return 'Error';
    } else if (credentialCheckStatus == undefined || credentialCheckStatus == null) {
      return 'Error';
    }
  };

  const credentialValidationStatus = () => {
    if (validationProcessingStatuses.includes(credentialCheckStatus)) {
      return PartnerIntegrationStatusEnum.IN_PROGRESS;
    } else {
      return PartnerIntegrationStatusEnum.ERROR;
    }
  };

  const propertiesStatus = integrationValidations.properties_match_status;
  const unitsStatus = integrationValidations.unit_match_status;
  const propertiesUnitsStatus = (): PartnerValidationStatus | undefined => {
    if (validationProcessingStatuses.includes(propertiesStatus) || validationProcessingStatuses.includes(unitsStatus)) {
      return PartnerIntegrationStatusEnum.IN_PROGRESS;
    } else if (
      propertiesStatus === PartnerIntegrationStatusEnum.SUCCESS &&
      unitsStatus === PartnerIntegrationStatusEnum.SUCCESS
    ) {
      return PartnerIntegrationStatusEnum.SUCCESS;
    } else if (
      propertiesStatus === PartnerIntegrationStatusEnum.ERROR ||
      unitsStatus === PartnerIntegrationStatusEnum.ERROR
    ) {
      return PartnerIntegrationStatusEnum.ERROR;
    }
  };
  const propertiesUnitsLabel = (): string | undefined => {
    if (validationProcessingStatuses.includes(propertiesStatus) || validationProcessingStatuses.includes(unitsStatus)) {
      return 'In progress';
    } else if (propertiesUnitsStatus() === PartnerIntegrationStatusEnum.ERROR) {
      return 'Error';
    }
  };

  const hasNoPolicyPushSetup = getLocal(`policy-push-${integration?.uuid}`) === '1';
  const hasNoCashDepositPushSetup = getLocal(`cash-deposit-push-${integration?.uuid}`) === '1';
  const hasNoCoverageRulesSetup = getLocal(`covg-rules-${integration?.uuid}`) === '1';

  const policyPushMessage = (partnerType) => {
    switch (partnerType) {
      case 'yardi':
        return 'When Yardi policy push is enabled, this will write information to custom fields on the tenant record about the SDI policy in Yardi';
    }
    return '';
  };

  const cashPushMessage = (partnerType) => {
    switch (partnerType) {
      case 'yardi':
        return 'When Yardi cash push is enabled, this will write information to custom fields on the tenant record about the cash deposit in Yardi';
    }
    return '';
  };

  const integrationTitle = `${
    integration?.type ? integration.type[0].toUpperCase() + integration.type.slice(1) : ''
  } Integration`;

  return (
    <>
      <DisplayField>
        <Platform id="integration-type">{integrationTitle}</Platform>
        <CoverageLabel htmlFor="integration-type">
          {integration?.actions.edit_integration && (
            <ActionLink id="editLink" variant="light" size="small" to={linkWithState}>
              Edit Integration Details
            </ActionLink>
          )}
        </CoverageLabel>
      </DisplayField>
      <DisplayField>
        <CoverageValue id="enabled-data-pull">
          Enable resident data pull
          <Tooltip icon="question-mark">
            <TooltipText>
              When enabled, we will actively pull resident information
              from the Partner's Property Management System (PMS).
              <h2><BoldText>Requires:</BoldText></h2>
              <ul>
                <li>Successful PMS integration setup</li>
              </ul>
            </TooltipText>
          </Tooltip>
        </CoverageValue>
        {credentialCheckStatus === PartnerIntegrationStatusEnum.SUCCESS ||
        credentialCheckStatus === PartnerIntegrationStatusEnum.NOT_VALIDATED ? (
          <DisplayFieldControls>
            <Switch
              aria-labelledby="enable-data-pull"
              aria-selected={dataPullEnabled}
              checked={dataPullEnabled}
              disabled={isUpdatePartnerLoading}
              onCheckedChange={updateDataPull}
              id="toggle-data-pull"
            />
          </DisplayFieldControls>
        ) : (
          <>
            {credentialValidationStatusLabel()?.length && (
              <CoverageLabel htmlFor="data-pull">
                <ValidationStatus status={credentialValidationStatus()} label={credentialValidationStatusLabel()} />
              </CoverageLabel>
            )}
          </>
        )}
      </DisplayField>

      <DisplayField>
        <CoverageValue id="enable-auto-invite">Enable coverage rules and/or Rhino product eligibility</CoverageValue>
        <DisplayFieldControls>
          {propertiesUnitsStatus() !== PartnerIntegrationStatusEnum.IN_PROGRESS && (
            <ActionButton
              id={'coverage-form-btn'}
              variant={'noLabel'}
              usage={hasNoCoverageRulesSetup ? 'emphasis' : 'quiet'}
              icon={'edit'}
              tooltip="Edit Coverage Rules"
              onClick={() => {
                // remove existing first time set up flag (if it exists)
                if (hasNoCoverageRulesSetup) removeLocal(`covg-rules-${integration?.uuid}`);
                navigate(
                  generatePath(PARTNER_INTEGRATION_ROUTES.coverage, {
                    propertyOwnerId,
                    partnerIntegrationId
                  })
                );
              }}
            />
          )}
          {propertiesUnitsStatus() === PartnerIntegrationStatusEnum.SUCCESS || !propertiesUnitsStatus() ? (
            <div>
              <Switch
                aria-labelledby="enable-auto-invite"
                aria-selected={autoInviteEnabled}
                checked={autoInviteEnabled}
                onCheckedChange={shouldToggleAutoInvite}
                disabled={hasNoCoverageRulesSetup}
                id="toggle-auto-invite"
              />
            </div>
          ) : (
            <>
              {propertiesUnitsLabel()?.length && (
                <CoverageLabel htmlFor="coverage-rules-invites">
                  <ValidationStatus status={propertiesUnitsStatus()} label={propertiesUnitsLabel()} />
                </CoverageLabel>
              )}
            </>
          )}
        </DisplayFieldControls>
      </DisplayField>

      {integration.type === 'realpage' && (
        <DisplayField>
          <IndentedCoverageValue id="immediate-conditional-invites">
            Send Conditional invitations immediately
          </IndentedCoverageValue>
          <DisplayFieldControls>
            <div>
              <Switch
                aria-labelledby="enable-immediate-conditional-invites"
                aria-selected={immediateConditionalInvitesEnabled}
                checked={immediateConditionalInvitesEnabled}
                onCheckedChange={updateImmediateConditionalInvites}
                id="toggle-immediate-conditional-invites"
              />
            </div>
          </DisplayFieldControls>
        </DisplayField>
      )}

      {/* Commented out section for AP-298 */}
      {integration.type !== 'entrata' && (
        <DisplayField>
          <CoverageValue id="enabled-policy-push">
            Enable policy push
            {policyPushMessage(integration.type) && (
              <Tooltip message={policyPushMessage(integration.type)} icon="question-mark" />
            )}
          </CoverageValue>
          <DisplayFieldControls>
            {integration.type === 'realpage' ? (
              <Switch id={'policy-push-toggle-switch'} checked={policyPushEnabled} onCheckedChange={updatePolicyPush} />
            ) : (
              <>
                <ActionButton
                  id="policyPush"
                  variant="noLabel"
                  usage={hasNoPolicyPushSetup ? 'emphasis' : 'quiet'}
                  icon="edit"
                  tooltip="Edit policy push"
                  onClick={() => {
                    // remove existing first time set up flag (if it exists)
                    if (hasNoPolicyPushSetup) removeLocal(`policy-push-${integration?.uuid}`);
                    navigate(
                      generatePath(PARTNER_INTEGRATION_ROUTES.yardiPush, {
                        partnerIntegrationId
                      })
                    );
                  }}
                />
                <Switch
                  aria-labelledby="enable-policy-push"
                  aria-selected={policyPushEnabled}
                  checked={policyPushEnabled}
                  onCheckedChange={updatePolicyPush}
                  id="toggle-policy-push"
                  disabled={disablePolicyAndCashDepositPushSwitch || hasNoPolicyPushSetup}
                />
              </>
            )}
          </DisplayFieldControls>
        </DisplayField>
      )}
      {integration.type == 'yardi' && (
        <DisplayField>
          <CoverageValue id="enabled-cash-deposit-push">
            Enable cash deposit push
            <Tooltip message={cashPushMessage(integration.type)} icon="question-mark" />
          </CoverageValue>
          <DisplayFieldControls>
            <>
              <ActionButton
                id="cashDepositPush"
                variant="noLabel"
                usage={hasNoCashDepositPushSetup ? 'emphasis' : 'quiet'}
                icon="edit"
                tooltip="Edit cash deposit push"
                onClick={() => {
                  // remove existing first time set up flag (if it exists)
                  if (hasNoCashDepositPushSetup) removeLocal(`cash-deposit-push-${integration?.uuid}`);
                  navigate(
                    generatePath(PARTNER_INTEGRATION_ROUTES.yardiPush, {
                      partnerIntegrationId
                    })
                  );
                }}
              />
              <Switch
                aria-labelledby="enable-cash-deposit-push"
                aria-selected={cashDepositPushEnabled}
                checked={cashDepositPushEnabled}
                onCheckedChange={updateCashDepositPush}
                id="toggle-cash-deposit-push"
                disabled={disablePolicyAndCashDepositPushSwitch || hasNoCashDepositPushSetup}
              />
            </>
          </DisplayFieldControls>
        </DisplayField>
      )}

      {['yardi', 'realpage'].includes(integration.type) && (
        <>
          <DisplayField>
            <CoverageValue id="enable-ledger-sync">
              Enable ledger sync
              <Tooltip icon="question-mark">
                <TooltipText>
                  When enabled, we will write to the resident ledger when
                  cash deposit funds have been confirmed to be collected by Rhino.
                </TooltipText>
              </Tooltip>
            </CoverageValue>
            <DisplayFieldControls>
              <Switch
                aria-labelledby="enable-ledger-sync"
                aria-selected={ledgerSyncEnabled}
                checked={ledgerSyncEnabled}
                onCheckedChange={updateLedgerSync}
                id="toggle-ledger-sync"
              />
            </DisplayFieldControls>
          </DisplayField>

          {integration.type === 'yardi' && (
            <DisplayField>
              <CoverageValue id="enable-ledger-sync-create-charge">
                Add "RHINO" to Ledger Description?
                <Tooltip icon="question-mark">
                  <TooltipText>
                    When enabled, this adds RHINO as additional text to the charge and payment ledger entries.
                    <h2><BoldText>Requires:</BoldText></h2>
                    <ul>
                      <li>Ledger Sync enabled</li>
                    </ul>
                  </TooltipText>
                </Tooltip>
              </CoverageValue>
              <DisplayFieldControls>
                <Switch
                  aria-labelledby="enable-ledger-sync-create-charge"
                  aria-selected={ledgerSyncTaggedTransactions}
                  checked={ledgerSyncTaggedTransactions}
                  onCheckedChange={updateLedgerSyncTaggedTransactions}
                  id="toggle-ledger-sync-create-charge"
                />
              </DisplayFieldControls>
            </DisplayField>
          )}

          {integration.type === 'yardi' && depositMemoEnabled && (
            <DisplayField>
              <CoverageValue id="write-to-deposit-memo">
                Write To Deposit Memo?
                <Tooltip icon="question-mark">
                  <TooltipText>
                    When enabled, Deposit Memo Push updates payment ledger
                    entries to contain the bank transaction statement descriptor
                    in the payment ledger entry Deposit Memo field.
                    <h2><BoldText>Requires:</BoldText></h2>
                    <ul>
                      <li>Ledger Sync enabled</li>
                    </ul>
                  </TooltipText>
                </Tooltip>
              </CoverageValue>
              <DisplayFieldControls>
                <Switch
                  aria-labelledby="write-to-deposit-memo"
                  aria-selected={writeToDepositMemo}
                  checked={writeToDepositMemo}
                  onCheckedChange={updateWriteToDepositMemo}
                  id="toggle-write-to-deposit-memo"
                />
              </DisplayFieldControls>
            </DisplayField>
          )}

          {integration.type === 'yardi' && ledgerSyncEnabled && (
            <DisplayField>
              <CoverageValue id="enable-ledger-sync-create-charge">
                Create Charge?
                <Tooltip icon="question-mark">
                  <TooltipText>
                    When enabled, Create Charge pushes both a charge entry and a payment
                    entry to the ledger when cash deposit funds are confirmed collected
                    by Rhino. When disabled, only a payment ledger entry is written.
                    <h2><BoldText>Requires:</BoldText></h2>
                    <ul>
                      <li>Ledger Sync enabled</li>
                    </ul>
                  </TooltipText>
                </Tooltip>
              </CoverageValue>
              <DisplayFieldControls>
                <Switch
                  aria-labelledby="enable-ledger-sync-create-charge"
                  aria-selected={ledgerSyncCreateCharge}
                  checked={ledgerSyncCreateCharge}
                  onCheckedChange={updateLedgerSyncCreateCharge}
                  id="toggle-ledger-sync-create-charge"
                />
              </DisplayFieldControls>
            </DisplayField>
          )}
          {integration.type === 'yardi' && ledgerSyncEnabled && ledgerSyncCreateCharge && (
            <ChargeCode
              chargeCodeValue={depositChargeCode}
              chargeCodeLabel={'Deposit Charge Code'}
              setChargeCode={setDepositChargeCode}
              updateChargeCode={updateDepositChargeCode}
            />
          )}
          <DisplayField>
            <CoverageValue id="enable-native-enrollment">Enable Leasing Integration (RLI)</CoverageValue>
            <DisplayFieldControls>
              <Switch
                aria-labelledby="enable-native-enrollment"
                aria-selected={nativeEnrollmentEnabled}
                checked={nativeEnrollmentEnabled}
                onCheckedChange={updateNativeEnrollment}
                id="toggle-native-enrollment"
              />
            </DisplayFieldControls>
          </DisplayField>
          <DisplayField>
            <CoverageValue>Renter URL</CoverageValue>
            <RenterLink>
              <a href={integration?.renter_url}>{integration?.renter_url}</a>
            </RenterLink>
          </DisplayField>
          {attachmentFiles.length <= 0 && (
            <DisplayField>
              <AttachmentLabel htmlFor="attachment-logo-dz">Attach Logo</AttachmentLabel>
              <div {...getRootProps()}>
                <input {...getInputProps({ id: 'attachment-logo-dz' })} />
              </div>
              <p css={[FONTS.p1, { marginBottom: 24 }]}>Upload a logo to use in RentCafe (150px x 50px)</p>
            </DisplayField>
          )}
          {attachmentFiles.length > 0 && (
            <DisplayField>
              <CoverageValue>Attached Logo</CoverageValue>
              {attachmentFiles.map((attachment) => (
                <AttachmentFileItem
                  key={attachment.filename}
                  attachment={attachment}
                  removeAttachment={removeAttachment}
                />
              ))}
            </DisplayField>
          )}
          <DisplayField>
            <CoverageValue id="show-sdi-coverage-amount">Show SDI coverage amount</CoverageValue>
            <DisplayFieldControls>
              <Switch
                aria-labelledby="show-sdi-coverage-amount"
                aria-selected={showSdiCoverageAmount}
                checked={showSdiCoverageAmount}
                onCheckedChange={updateShowSdiCoverageAmount}
                id="toggle-show-sdi-coverage-amount"
              />
            </DisplayFieldControls>
          </DisplayField>
          {isAdmin && (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              <Subtitle>Sync Data</Subtitle>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center' }}>
                {integration.type === 'yardi' && (
                  <NumericTextInput
                    id="days"
                    label="Pull prospects updated this many days ago"
                    onChange={(e) => {
                      setProspectSyncDays(parseInt(e.target.value));
                    }}
                    value={prospectSyncDays}
                    type="number"
                  />
                )}
                <IntegrationButton
                  id="pull-properties"
                  variant="interaction"
                  size="small"
                  onClick={onPullProperties}
                >
                  Sync Data
                </IntegrationButton>
              </div>
            </div>
          )}
          <Subtitle>Collect Charges</Subtitle>
          {yardiAttributes?.charge_code_availability_warning && (
            <StyledContent>
              <FieldError>Unable to fetch charge codes from Yardi:</FieldError>
              <FieldError>{yardiAttributes?.charge_code_availability_warning}</FieldError>
            </StyledContent>
          )}
          <div style={{ display: 'flex', flexDirection: 'column', paddingBlock: 16 }}>
            {yardiAttributes?.charge_codes_attributes?.map((chargeCode, index) => (
              <Checkbox
                id={`yardi_charge_code_${chargeCode.id}`}
                checked={chargeCode.collected || false}
                onCheckedChange={(enabled) => updateYardiChargeCode(chargeCode.id, enabled)}
                label={chargeCode.description}
              />
            ))}
          </div>
          <PropertyOwnerFormLabel id="health-check">
            Perform Health Checks
            <DisplayFieldControls>
              <Switch
                aria-labelledby="health-check"
                aria-selected={healthCheckEnabled}
                checked={healthCheckEnabled}
                onCheckedChange={updateHealthCheck}
                id="toggle-health-check"
              />
            </DisplayFieldControls>
          </PropertyOwnerFormLabel>
        </>
      )}

      <FlexButtonContainer>
        {(integration?.actions.validate_integration ||
          integration?.integration_status === PartnerIntegrationStatusEnum.NOT_VALIDATED) && (
          <IntegrationButton
            id={'edit-integration'}
            variant={'interaction'}
            usage={'secondary'}
            size={'small'}
            onClick={onValidatePartnerIntegration}
            disabled={isValidatePartnerIntegrationLoading}
          >
            Validate
          </IntegrationButton>
        )}
      </FlexButtonContainer>
      <WarningModal
        title="Are you sure you want to enable auto-invites?"
        subTitle={
          <>
            Your renters will start receiving emails inviting them to sign up for Rhino security deposit insurance
            policies as soon as you click <BoldText>Yes, enable auto-invites</BoldText>. Are you sure you want to turn
            on auto-invites?
          </>
        }
        confirmButtonText="Yes, enable auto-invites"
        isOpen={showAutoInviteWarning}
        handleConfirm={updateAutoInvite}
        closeModal={closeWarningModal}
      />
    </>
  );
};

const Integration = ({ partnerIntegrationId, logo }) => {
  const { isLoading, isSuccess, data } = usePartnerIntegration(partnerIntegrationId) ?? {};

  if (!isLoading) {
    if (isSuccess && data?.integration) {
      return (
        <IntegrationControls integration={data.integration} partnerIntegrationId={partnerIntegrationId} logo={logo} />
      );
    } else {
      return (
        <div>
          <h3>An error has occurred, please select another property owner.</h3>
        </div>
      );
    }
  } else {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }
};

export default Integration;
